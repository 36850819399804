<template>
  <div class="text-light pt-3 px-5 container">
    <section
      style="
        height: 100vh;
        display: flex;
        place-content: center;
        padding-top: 7rem;
      "
    >
      <div class="text-center" data-aos="fade-up">
        <img src="https://github.blog/wp-content/uploads/2013/04/0cf7be70-a5e3-11e2-8943-6ac7a953f26d.jpg?resize=1234%2C631" alt="github-image" class="w-50 mb-2">
        <h1>Basic Github <font-awesome-icon icon="fa-brands fa-github" /></h1>
      </div>
    </section>
    <section data-aos="fade-up" v-for="cntn in content" :id="cntn.link">
      <h2>{{ cntn.title }}</h2>
      <hr />
      <div v-for="body in cntn['content']">
        <!-- Sub Title -->
        <div v-if="body.sub" class="mt-4">
          <h4>{{ body.sub }}</h4>
        </div>
        <!-- Sub Title -->

        <!-- Sub Logo With Logo -->
        <div v-if="body.subLogo" class="mt-4">
          <h4>{{ body.subLogo.sub }} <font-awesome-icon :icon="body.subLogo.icon" /> </h4>
        </div>
        <!-- Sub Logo With Logo -->

        
        <!-- Link -->
        <a v-if="body.link" :href="body.link.url" target="_blank" class="mb-3 d-block"> {{ body.link.name }}</a>
        <!-- Link -->

        <!-- Paragraf -->
        <p v-if="body.paragraf">{{ body.paragraf }}</p>
        <!-- Paragraf -->

        <!-- Code -->
        <div v-if="body.code" class="block-code p-3 mb-3">
          <Prism class="code mb-0" language="javascript">{{ body.code }}</Prism>
        </div>
        <!-- Code -->

      </div>
      <hr class="mt-5" />
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import materi from "@/utils/materi";
import Prism from "vue-prism-component";
import "@/assets/css/highlight.css";
import { useRoute } from "vue-router";
import router from "@/router";

// const content = navigate[2];
const materiId = useRoute().params.materi
const code = `git clone test`;

const isCollapse = ref(false);

if (materi[materiId] == undefined) {
  router.push({name: "notFound404"})
}


const content = materi[materiId][2]


onMounted(() => {
  AOS.init();
});
</script>
