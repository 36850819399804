import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import router from "./router";
import "@/assets/css/highlight.css";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add some free styles */
import { faTwitter, faGithub,faWindows,faApple,faLinux } from "@fortawesome/free-brands-svg-icons";
import { faUserSecret, faUser } from "@fortawesome/free-solid-svg-icons";

/* add each imported icon to the library */
library.add(faTwitter, faUserSecret, faUser, faGithub,faWindows,faApple,faLinux);

const vue = createApp(App);
vue.use(router);
vue.use(ElementPlus);
vue.component("font-awesome-icon", FontAwesomeIcon);

vue.mount("#app");
