<template>
  <div class="container pt-5">
    <el-row class="mt-3">
      <el-col :span="11" class="editor me-4">
        <el-input
          v-model="builderMd"
          autosize
          type="textarea"
          placeholder="Please input"
          spellcheck="false"
          :rows="2"
        />
        <el-button @click="copy">Copy</el-button>
      </el-col>
      <el-col :span="12" class="text-white">
        <div>
        </div>
        <VueMarkdown :options="option" :source="builderMd"></VueMarkdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";

export default {
  data() {
    return {
      builderMd:`# Penjelasan

iya ini penjelasan`,
      option: {
        html: true,
        linkify: true,
        typographer: true,
      },
    };
  },

  methods : {
    copy(){
      navigator.clipboard.writeText(this.builderMd);
    }
  },

  components: {
    VueMarkdown,
  },
};
</script>

<style>
.text-editor {
  background: none;
  border: none;
  outline: none;
}
</style>
