export default [
    {
        'date': {
            'timestamp' : '17/12/2022',
            'time' : "17 Desember 2022"
        },
        'title': "Mempelajari Git",
        'description': "Mempelajari basic dari git, yang akan digunakan untuk menyimpan kodingan kalian.",
        'link':"github"
    },
    {
        'date': {
            'timestamp' : '7/1/2023',
            'time' : "7 Januari 2023"
        },
        'title': "Mempelajari Basic HTML",
        'description': "IT Club divisi Programming sudah mulai masuk pada materi web nih temen-temen. ",
        'link':"comingsoon"
    },
    {
        'date': {
            'timestamp' : '17/12/2022',
            'time' : "21 Januari 2023"
        },
        'title': "Mempelajari Basic PHP",
        'description': "Kali ini programming mempelajari salah satu bahasa backend yaitu php.",
        'link':"comingsoon"
    }
]