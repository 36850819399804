export default [
  // Title
  { heading: "Git" },
  // Sidebar
  [
    {
      title: "Penjelasan",
      link: "#penjelasan",
    },
    {
      title: "Instalasi",
      link: "#instalasi",
    },
    {
      title: "Koneksi Akun",
      link: "#koneksi-akun",
    },
    {
      title: "Setup Repository",
      link: "#setup-repository",
    },
  ],
  // Content
  [
    // Penjelasan
    { 
      title: "Penjelasan",
      link: "penjelasan",
      content: [
        {
          paragraf:
            "Halo teman-teman <bold>programming</bold>, disini kita akan mempelajari git, apasih github itu? dan apa tujuannya?",
        },
        {
          paragraf:
            "Git biasa nya digunakan untuk membagikan code dan menyimpan code kalian yang bertujuan supaya code tidak hilang dan mempunyaik backup. software git yang paling sering digunakan adalah Github atau Gitlab pada pembelajaran kali ini kita akan mempelajari github.",
        },
        {
          paragraf:
            "Alasan kenapa kita menggunakan github adalah karena sedang ramai digunakan oleh banyak programmer dan tampilan yang di suguhkan sangat ui friendly.",
        },
      ],
    },
    // End Penjelasan

    // Instalasi
    {
      title: "Instalasi",
      link: "instalasi",
      content: [
        {
          paragraf:
            "Sebelum masuk lebih dalam kita install dulu salah satu aplikasi yang wajib sebelum mengenal github yaitu GIT. GIT ini digunakan untuk mengoneksikan computer kalian dengan storage yang berada pada Github atau Gitlab. Berikut cara menginstall GIT pada semua operasi sistem.",
        },
        {
          subLogo: {
            sub: "Windows",
            icon: "fa-brands fa-windows",
          },
        },
        {
          link: {
            name: "Windows Download",
            url: "https://git-scm.com/download/win",
          },
        },
        {
          paragraf:
            "Pilih mengikuti bit pada operasi windows kalian 32-bit atau 64-bit, jika sudah terdownload maka  klik dan install seperti biasa next next dan oke",
        },
        {
          subLogo: {
            sub: "Mac Os",
            icon: "fa-brands fa-apple",
          },
        },
        {
          paragraf:
            "Cara Menginstall git pada Mac os menggunkanan terminal buka terminal lebih dahulu, dengan cara seperti ini:",
        },
        {
          code: `//Install Hombrew Terlebih dahulu jika belum terinstall copy dibawah pada terminal
/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/install.sh)"

// Tunggu dan Jika Sudah Terinstall Maka Jalankan Perintah Berikut
// Tunggu jika selesai maka git berhasil terinstall pada operation system anda
brew install git`,
        },
        {
          subLogo: {
            sub: "Linux",
            icon: "fa-brands fa-linux",
          },
        },
        {
          paragraf:
            "Cara Menginstall git pada Linux disini saya menggunakan ubuntu-22.04 dan cara installnya juga menggunakan terminal, maka buka terminal dan ketik perintah dibawah ini",
        },
        {
          code: `//Pertama Tambahkan Repository Git ke APT
sudo add-apt-repository ppa:git-core/ppa

// Jika sudah jalankan perintah dibawah untuk mendownload sekaligus menginstall GIT
sudo apt-get update && sudo apt-get -y install git 

// Jika sudah selesai ketik seperti berikut untuk mengecek apakah sudah terinstall atau belum
git --version `,
        },
      ],
    },
    // End Instalasi

    // Koneksi Akun
    {
      title: "Koneksi Akun",
      link: "koneksi-akun",
      content: [
        {
          paragraf:
            "Setelah kalian menginstall git yang perlu dilakukan adalah membuat akun git, dikarenakan kita menggunakan github maka buat akun pada github terlebih dahulu. Jika sudah membuat akun maka selanjutnya adalah login menggunakan akun kalian pada GIT yang sudah kalian install tadi.",
        },

        {
          code: `//Ikuti Command dibawah ini, pertama masukan nama 
git config --global user.name "FIRST_NAME LAST_NAME" //ganti "FIRST_NAME LAST_NAME" dengan nama kalian


//Ikuti Command dibawah ini, kedua masukan email kalian pakai untuk membuat akun
git config --global user.email "MY_NAME@example.com" //ganti seperti email yang kalian gunakan
`,
        },
        {
          paragraf:
            "tidak hanya itu kalian juga bisa menggunakan SSH jika kalian tertarik kalian bisa mengeklik link tersebut untuk mempelajarinya",
        },
        {
          link: {
            name: "Login Dengan SSH",
            url: "https://docs.github.com/en/authentication/connecting-to-github-with-ssh",
          },
        },
        {
          paragraf:
            "Jika ada kebingungan bertanyalah karena",
        },
      ],
    },
    // End Koneksi Akun

    // Membuat Repostory
    {
      title: "Setup Repository",
      link: "setup-repository",
      content: [
        {
          paragraf:
            "Setelah kalian setup global config, selanjutnya buatlah repository pada github kalian,",
        },
      ],
    },
    // End Membuat Repostory
  ],
];
