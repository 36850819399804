<template>
  <div>
    <el-row class="fullHeight">
      <el-col :span="4">
        <Sidebar  />
      </el-col>
      <el-col :span="20" class="w-100">
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";

export default {
  name: "Dashboard",
  components: {
    Sidebar,
  },
};
</script>
<style>
.fullHeight {
  height: 100vh;
}
</style>
