import { createRouter, createWebHashHistory } from "vue-router";
import NotFound from "../layouts/NotFound";
import ComingSoon from "../layouts/ComingSoon";
import TimelineLayout from "../layouts/TimelineLayout";

// Materi
import Materi from "../layouts/MateriLayout";
import MateriDetail from "../pages/materi/Materi";


// Builder 
import BuilderMD from "../layouts/BuilderMD"
import BuilderJSON from "../layouts/BuilderJSON"

const routes = [
  {
    path: "/",
    name: "home",
    component: TimelineLayout,
  },
  {
    path: "/materi",
    component: Materi,
    children: [{ path: ":materi", component: MateriDetail }],
  },
  {
    path: "/comingsoon",
    component: ComingSoon,
    name: "comingsoon",
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/404",
    name: "notFound404",
    component: NotFound,
  },
  {
    path: "/builder/md",
    name: "builder-md",
    component: BuilderMD,
  },
  {
    path: "/builder/json",
    name: "builder-json",
    component: BuilderJSON,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      console.log("Apakah Masuk Disini?");
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash,};
    }
    return { top: 0};
  },
});

export default router;
