<template>
  <div class="container pt-5">
    <el-button type="success" @click="addSection">Add Section</el-button>
    <el-row class="mt-3">
      <el-col :span="11" class="editor me-4">
        <section
          class="text-white"
          v-for="(cntn, indexSection) in builderJson"
          :id="cntn.link"
        >
          <input
            class="text-editor fs-2"
            v-model="builderJson[indexSection].title"
          />
          <hr />
          <div v-for="(body, indexContent) in cntn['content']">
            <!-- Sub Title -->
            <div v-if="body.sub" class="mt-4">
              <h4>{{ body.sub }}</h4>
            </div>
            <!-- Sub Title -->

            <!-- Sub Logo With Logo -->
            <div v-if="body.subLogo" class="mt-4">
              <h4>
                {{ body.subLogo.sub }}
                <font-awesome-icon :icon="body.subLogo.icon" />
              </h4>
            </div>
            <!-- Sub Logo With Logo -->

            <!-- Link -->
            <a
              v-if="body.link"
              :href="body.link.url"
              target="_blank"
              class="mb-3 d-block"
            >
              {{ body.link.name }}</a
            >
            <!-- Link -->

            <!-- Paragraf -->
            <!-- <p v-if="body.paragraf">{{ body.paragraf }}</p> -->
            <textarea
              class="text-editor fs-6"
              v-if="body.paragraf"
              v-model="builderJson[indexSection].content[indexContent].paragraf"
              name=""
              id=""
            />
            <!-- Paragraf -->

            <!-- Code -->
            <div v-if="body.code" class="block-code p-3 mb-3">
              <Prism class="code mb-0" language="javascript">{{
                body.code
              }}</Prism>
            </div>
            <!-- Code -->
          </div>
          <el-dropdown>
            <el-button type="success"> + </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="addParagraf(indexSection)"
                  >Add Paragraf</el-dropdown-item
                >
                <el-dropdown-item>Action 2</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <hr class="mt-5" />
        </section>
      </el-col>
      <el-col :span="12">
        <el-radio-group v-model="isCode" class="ml-4">
          <el-radio :label="true" size="large">Code</el-radio>
          <el-radio :label="false" size="large">Preview</el-radio>
        </el-radio-group>
        <pre v-if="isCode" class="text-white pre-preview">
            {{ builderJson }}
        </pre>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import he from "he";

export default {
  data() {
    return {
      isCode: false,
      builderJson: [
        {
          title: "Builder",
          link: "builder",
          content: [],
        },
      ],

      he: he,
    };
  },

  methods: {
    addSection() {
      this.builderJson.push({
        title: "Builder",
        link: "builder",
        content: [],
      });
    },

    addParagraf(index) {
      this.builderJson[index].content.push({
        paragraf: "paragraf builder",
      });
    },
  },
};
</script>

<style>
.pre-preview {
  white-space: pre-wrap;
}

.text-editor {
  white-space: pre-wrap;
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
</style>
