<template>
  <div>
    <el-menu
      style="position: fixed;"
      router
      class="sidebar"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
    >
      <a
        href="#"
        class="mb-0 fs-4 fw-semibold text-light pt-3 pb-2 d-block px-3 text-decoration-none"
        >{{ navigateTop.heading }}</a
      >
      <el-menu-item v-for="(navi, index) in navigateLink" :index="navi.link">
        <template #title>{{ navi.title }}</template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { ref } from "vue";
import navigate  from "@/utils/materi/index";
import { useRoute } from "vue-router";
import router from "@/router";

const materiId = useRoute().params.materi


if (navigate[materiId] == undefined) {
  router.push({name: "notFound404"})
}

const navigateLink = navigate[materiId][1]
const navigateTop = navigate[materiId][0]


const route = useRoute();

const active = '#' + route.fullPath.split('#')[1]

const isCollapse = ref(false);
const handleOpen = (key, keyPath) => {
  console.log(key, keyPath);
};
const handleClose = (key, keyPath) => {
  console.log(key, keyPath);
};
</script>

<style>
.sidebar {
  width: 225px;
  height: 100vh;
  min-height: 100vh;
}
</style>
